








































































































































































import TopBar from "@/components/common/TopBar.vue";
import StaticTable from "@/components/table/StaticTable.vue";
import Widget from "@/components/common/Widget.vue";
import VerticalLineAndBarSingleChart from "@/components/charts_js/VerticalLineAndBarSingleChart.vue";
import VerticalLineAndBarGroupChart from "@/components/charts_js/VerticalLineAndBarGroupChart.vue";
import SmallPie from "@/components/charts/SmallPie.vue";
import UmsatzHorizontalBar from "@/components/charts/UmsatzHorizontalBar.vue";
import Vue from "vue";
import Component from "vue-class-component";
import store from "../../store";
import { Watch } from "vue-property-decorator";
import {
  monthChart,
  monthChartData,
  drawVerticalStackBarByPeriod,
  combineMonthToQuarter,
} from "../../helper/charts";
import {
  callLieferantentop5,
  callMaterialdetail,
  callPrevMaterialdetail,
  callCalc,
  callSuppliers,
} from "../../helper/utils";

@Component({
  components: {
    TopBar,
    Widget,
    StaticTable,
    VerticalLineAndBarSingleChart,
    SmallPie,
    UmsatzHorizontalBar,
    VerticalLineAndBarGroupChart,
  },
})
export default class Lieferantenanalyse extends Vue {
  initPage = false;
  data: { d0: any; d1: any } = { d0: {}, d1: {} };
  get lieferanten() {
    let lieferantenHead: any = [];
    store.watch(
      (getter) => {
        if (
          getter.prevMaterialDetail &&
          (getter.prevMaterialDetail as any).kontotable.length > 0
        ) {
          lieferantenHead = [
            { title: "Bereich", class: "vertical-align-middle" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
            {
              title: "Vergleich",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0] - 1,
              class: "text-center",
            },
            {
              title: "Abweichung",
              description: "Ist/Referenz",
              class: "text-center",
              colspan: 2,
            },
          ];
        } else {
          lieferantenHead = [
            { title: "Bereich", class: "vertical-align-middle" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
          ];
        }
      },
      (getters) => {}
    );
    return {
      head: lieferantenHead,
      body: {
        prev:
          store.getters.prevMaterialDetail &&
          store.getters.prevMaterialDetail.kontotable,
        current:
          store.getters.materialDetail &&
          store.getters.materialDetail.kontotable,
      },
    };
  }

  get chartData() {
    return {
      cur: store.getters.materialDetail,
      prev: store.getters.prevMaterialDetail,
      resize: store.getters.resize,
    };
  }

  get lieferantenTop() {
    return {
      data: store.getters.lieferantenTop,
      resize: store.getters.resize,
    };
  }

  @Watch("chartData")
  onChartDataChange() {
    const result = monthChartData(
      (this.chartData.cur && this.chartData.cur.monthtable) || [],
      (this.chartData.prev && this.chartData.prev.monthtable) || [],
      "Material/Wareneinkauf",
      "Material/Wareneinkauf (Vorjahr)",
      "chart_ekgesamt"
    );

    this.data = { d0: result.d0, d1: result.d1 };
  }

  @Watch("lieferantenTop")
  onLieferantenTopChange() {
    drawVerticalStackBarByPeriod(
      this.lieferantenTop.data || [],
      "chart_ek_top5"
    );
  }

  mounted() {
    this.initPage = true;
    this.onChartDataChange();
    this.onLieferantenTopChange();
    this.onTopBarDataChange();
    if (localStorage.getItem("from") == "ek_table") {
      (document.getElementById("ek_table") as any).scrollIntoView();
      localStorage.removeItem("from");
    } else if (
      localStorage.getItem("from") &&
      document.getElementById(localStorage.getItem("from") || "")
    ) {
      (document.getElementById(
        localStorage.getItem("from") || ""
      ) as any).scrollIntoView();
      localStorage.removeItem("from");
    }
  }

  get topBarData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
      rangeStep: store.getters.rangeStep,
    };
  }

  get topBarNavigation() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
      rangeStep: store.getters.rangeStep,
    };
  }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }

  pushStateHistory(stb: any, mandant: any, year: any, range: any) {
    const selectedStb = this.biAgency != null ? this.biAgency : stb;

    let path =
      "/" +
      this.$route.name +
      "/" +
      encodeURIComponent(selectedStb) +
      "/" +
      encodeURIComponent(mandant) +
      "/" +
      encodeURIComponent(year) +
      "/" +
      encodeURIComponent(range);
    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  @Watch("topBarNavigation")
  onTopBarNavigationChange() {
    // change URL based on seleted parameters in top bar
    let mandant: string = this.topBarNavigation.client[1];
    let year: string = this.topBarNavigation.year[0];
    let stb: string =
      this.topBarNavigation.client && this.topBarNavigation.client[0];

    this.pushStateHistory(stb, mandant, year, store.getters.rangeStep);
  }

  @Watch("topBarData")
  onTopBarDataChange() {
    if (this.topBarData.year && this.topBarData.client) {
      // force to update if range is changed
      let params: any = {};

      // if (this.topBarData.rangeStep) {
      //   store.dispatch("updateIsLieferantentop5Called", false);
      //   store.dispatch("updateIsMaterialdetailCalled", false);
      //   store.dispatch("updateIsPrevMaterialdetailCalled", false);
      //   store.dispatch("updateIsSuppliersCalled", false);
      //   store.dispatch("updateIsCalcCalled", false);

      //   // TODO: get parameters based on filter
      // }

      if (!store.getters.isCalcCalled) {
        callCalc();
      }
      if (!store.getters.isLieferantentop5Called) {
        callLieferantentop5({});
      }
      if (!store.getters.isMaterialdetailCalled) {
        callMaterialdetail({});
      }
      if (!store.getters.isPrevMaterialdetailCalled) {
        callPrevMaterialdetail({});
      }
      if (!store.getters.isSuppliersCalled) {
        callSuppliers();
      }

      if (this.initPage) {
        this.onTopBarNavigationChange();
        this.initPage = false;
      }
    }
  }
}
